<template>
  <CSidebar :minimize="minimize" unfoldable :show="show" @update:show="(value) => updateSidebarView(value)">
    <CSidebarBrand tag="div">
      <div class="logo c-sidebar-brand-full">
        <span>MB</span> <img :src="logo" alt="" />
        <span class="logo__networks">NETWORKS</span>
      </div>

      <div class="logo c-sidebar-brand-minimized"><img :src="logo" alt="" /></div>
    </CSidebarBrand>

    <MbnSpinner v-if="isLoading" />

    <span v-if="error">Error</span>

    <template v-if="roles.length">
      <SidebarNavs />
      <CSidebarMinimizer class="c-d-md-down-none" @click.native="toggleSidebar" />
    </template>
  </CSidebar>
</template>

<script>
import logo from '@/assets/img/logo.png';
import MbnSpinner from '@/components/Spinner';
import SidebarNavs from './SidebarNavs';
import { mapState, mapMutations, mapActions } from 'vuex';

import { GET_ROLES } from '@/store/action-types/roles';

import { SET, TOGGLE } from '@/store/mutation-types/ui';

export default {
  name: 'TheSidebar',
  components: {
    SidebarNavs,
    MbnSpinner,
  },
  data() {
    return {
      logo,
    };
  },
  computed: {
    ...mapState('roles', ['isLoading', 'error', 'roles']),
    show() {
      return this.$store.state.ui.sidebarShow;
    },
    minimize() {
      return this.$store.state.ui.sidebarMinimize;
    },
  },
  mounted() {
    this.GET_ROLES();
  },
  methods: {
    ...mapMutations('ui', [TOGGLE, SET]),
    ...mapActions('roles', [GET_ROLES]),
    toggleSidebar() {
      this.TOGGLE('sidebarMinimize');
    },
    updateSidebarView(value) {
      this.SET(['sidebarShow', value]);
    },
  },
};
</script>
