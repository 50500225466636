<template>
  <CRenderFunction flat :content-to-render="sidebarLinks" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SidebarNavs',
  computed: {
    ...mapGetters('roles', ['isAdministrator', 'isWebmaster', 'isAdvertiser']),
    sidebarWebmaster() {
      return [
        {
          _name: 'CSidebarNav',
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: this.$t('sidebar.profile'),
              to: '/profile-webmaster',
              icon: 'cil-user',
              exact: false,
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('sidebar.sites'),
              to: '/sites',
              icon: 'cil-puzzle',
              exact: false,
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('sidebar.blocks'),
              to: '/blocks',
              icon: 'cil-applications-settings',
              exact: false,
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('sidebar.statistics'),
              to: '/statistics/webmaster',
              icon: 'cil-chart',
              exact: false,
            },
          ],
        },
      ];
    },
    sidebarAdvertiser() {
      return [
        {
          _name: 'CSidebarNav',
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: this.$t('sidebar.profile'),
              to: '/profile-advertiser',
              icon: 'cil-user',
              exact: false,
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('sidebar.campaigns'),
              to: '/campaigns',
              icon: 'cil-share-alt',
              exact: false,
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('sidebar.creatives'),
              to: '/creatives',
              icon: 'cib-atom',
              exact: false,
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('sidebar.statistics'),
              to: '/statistics/advertiser',
              icon: 'cil-chart',
              exact: false,
            },
          ],
        },
      ];
    },
    sidebarAdministrator() {
      return [
        {
          _name: 'CSidebarNav',
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: this.$t('sidebar.advertisers'),
              to: '/admin/advertisers',
              icon: 'cil-audio',
              exact: false,
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('sidebar.webmasters'),
              to: '/admin/webmasters',
              icon: 'cil-code',
              exact: false,
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('sidebar.sites'),
              to: '/admin/sites',
              icon: 'cil-puzzle',
              exact: false,
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('sidebar.creatives'),
              to: '/admin/creatives',
              icon: 'cib-atom',
              exact: false,
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('sidebar.statistics'),
              to: '/admin/statistics',
              icon: 'cil-chart',
              exact: false,
            },
          ],
        },
      ];
    },
    sidebarLinks() {
      if (this.isWebmaster) {
        return this.sidebarWebmaster;
      } else if (this.isAdvertiser) {
        return this.sidebarAdvertiser;
      } else {
        return this.sidebarAdministrator;
      }
    },
  },
};
</script>
