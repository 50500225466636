import en from '@/assets/img/flags/gb.svg';
import ru from '@/assets/img/flags/ru.svg';
import uz from '@/assets/img/flags/uz.svg';
import az from '@/assets/img/flags/az.svg';

const languageList = [
  {
    code: 'en',
    title: 'English',
    flag: en,
  },
  {
    code: 'ru',
    title: 'Русский',
    flag: ru,
  },
  {
    code: 'uz',
    title: "O'zbek",
    flag: uz,
  },
  {
    code: 'az',
    title: 'Azərbaycan',
    flag: az,
  },
];

export { languageList };
