<template>
  <CFooter :fixed="false">
    <div class="ml-auto">
      <span class="mr-1">Mostbet Networks {{ new Date().getFullYear() }}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
};
</script>
