<template>
  <CHeader with-subheader>
    <CToggler in-header class="ml-3 d-lg-none" @click="toggleSidebar('mobile')" />
    <CToggler in-header class="ml-3 d-md-down-none" @click="toggleSidebar('desktop')" />
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3"> </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <CHeaderNavItem class="px-2">
        <button class="c-header-nav-btn" @click="toggleThemeMode">
          <CIcon v-if="$store.state.ui.darkMode" name="cil-sun" />
          <CIcon v-else name="cil-moon" />
        </button>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <CHeaderNavItem class="px-2">
        <CDropdown size="sm" nav placement="bottom-end">
          <template #toggler>
            <div class="dropdown">
              <button
                type="button"
                aria-expanded="false"
                aria-haspopup="true"
                class="btn dropdown-toggle btn-sm d-flex align-items-center"
              >
                <img :src="flag.flag" class="flag" width="20" height="22" alt="flag" />
                <span class="ml-1">{{ interfaceLanguage.toUpperCase() }}</span>
              </button>
            </div>
          </template>
          <CDropdownItem
            v-for="language in languageList"
            :key="language.code"
            class="d-flex"
            @click="changeInterfaceLanguage(language.code)"
          >
            <img class="mr-1" :src="language.flag" width="20" height="22" alt="flag" />
            <span>{{ language.code.toUpperCase() }}</span>
          </CDropdownItem>
        </CDropdown>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <CHeaderNavItem class="pr-3 pl-2">
        <CButton size="sm" color="info" @click="logout">
          <CIcon name="cil-lock-locked" />
          {{ $t('ui.logout') }}
        </CButton>
      </CHeaderNavItem>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import { languageList } from '@/translations/language-list.js';
import { mapState, mapMutations } from 'vuex';

import {
  TOGGLE_SIDEBAR_DESKTOP,
  TOGGLE_SIDEBAR_MOBILE,
  TOGGLE,
  SET_INTERFACE_LANGUAGE,
} from '@/store/mutation-types/ui';

import { LOGOUT } from '@/store/mutation-types/auth';
import { CLEAR_ROLES } from '@/store/mutation-types/roles';

export default {
  name: 'TheHeader',
  data() {
    return {
      languageList,
    };
  },
  computed: {
    ...mapState('ui', ['interfaceLanguage', 'darkMode']),
    flag() {
      return languageList.find((lang) => lang.code === this.interfaceLanguage);
    },
  },
  methods: {
    ...mapMutations('ui', [TOGGLE_SIDEBAR_DESKTOP, TOGGLE_SIDEBAR_MOBILE, TOGGLE, SET_INTERFACE_LANGUAGE]),
    ...mapMutations('auth', [LOGOUT]),
    ...mapMutations('roles', [CLEAR_ROLES]),
    logout() {
      this.LOGOUT();
      this.CLEAR_ROLES();
      localStorage.removeItem('accessToken');
      localStorage.removeItem('roles');
      this.$router.push({ name: 'login' });
    },
    toggleSidebar(variant) {
      variant === 'mobile' ? this.TOGGLE_SIDEBAR_MOBILE() : this.TOGGLE_SIDEBAR_DESKTOP();
    },
    toggleThemeMode() {
      this.TOGGLE('darkMode');

      if (this.darkMode) {
        localStorage.setItem('theme-mode', 'darkMode');
      } else {
        localStorage.removeItem('theme-mode');
      }
    },
    changeInterfaceLanguage(interfaceLanguage) {
      this.$i18n.locale = interfaceLanguage;
      localStorage.setItem('interfaceLanguage', interfaceLanguage);
      this.SET_INTERFACE_LANGUAGE(interfaceLanguage);
    },
  },
};
</script>
